import { IBlock } from '../../../framework/src/IBlock';
import { Message } from '../../../framework/src/Message';
import { BlockComponent } from '../../../framework/src/BlockComponent';
import MessageEnum, {
  getName,
} from '../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../framework/src/RunEngine';

// Customizable Area Start
import {
  imgPasswordInVisible,
  imgPasswordVisible,
  defaultProfile,
} from './assets';
import React from 'react';
import dayjs from "dayjs";
import { getStorageData, removeStorageData } from '../../../framework/src/Utilities';
import { calculateSlots } from '../../../components/src/datesRangeHelper';
import moment from 'moment';
import { bookingColorTable } from './bookingColorTable';
import { applyUserAction, getTransContent } from '../../../components/src/helpers';

export type Duration = { id: number, duration: string, dates: string[], price: string, timeslots: string[] };

type BookingFormValues = {
  name: string;
  phone: string;
  gender: string;
  email: string;
  serviceName: { id: string; name: string };
  serviceCategory: { id: string; name: string };
  serviceSubCategory: { id: string; name: string };
  date: string;
  duration: { id: number; duration: string };
  slots: string;
  notes?: string;
};

interface CompleteAttributes {
  country: string;
  address: string | undefined;
  city: string;
  postal_code: string;
}

export type RescheduleFormValues = {
  name: string;
  serviceName: string;
  duration?: Duration;
  slot: string;
  date: string;
  price: number;
};

type CustomerProfile = {
  id: number;
  image_url: string;
  name: string;
  shortDescription: string;
  customerInfo?: {
    fullName: string;
    phoneNumber: number;
    email: string;
    gender: string;
    totalAmount: string;
  };
};

type BookingsCount = {
  completed: number;
  booked: number;
  cancelled: number;
};

export type ModalConfig = {
  title: string,
  message: string,
  confirmText: string,
  cancelText: string,
  onConfirm: () => void,
};

export const initBookingFormValues = {
  name: '',
  phone: '',
  gender: '',
  email: '',
  serviceName: { id: "0", name: "" },
  serviceCategory: { id: "0", name: "" },
  serviceSubCategory: { id: "0", name: "" },
  date: '',
  duration: { id: 0, duration: "" },
  slots: "",
  notes: '',
};

export const initRescheduleFormValues = {
  name: "",
  serviceName: "",
  duration: {
    id: 0,
    duration: '',
    price: '',
    dates: [],
    timeslots: []
  },
  slot: "",
  date: "",
  price: 0
};

const initScheduleItem = {
  id: "",
  name: "",
  color: "#059669",
  backgroundColor: "#059669",
  title: "",
  start: "",
  time: "",
  photo: "",
  price: "",
  duration: "",
  category: '',
  arCategory: '',
  display: "list-item",
  classNames: ["green-card"],
}

export interface CategorySubCategory {
  id: string;
  name: string;
}

export interface Category {
  id: string;
  attributes: {
    name: string;
    sub_categories: CategorySubCategory[];
  };
}

export enum BookingStatusEnum {
  "booked" = "Upcoming",
  "completed" = "Completed",
  "cancelled" = "Cancelled"
}

export type Status = "booked" | "completed" | "cancelled"

export enum CancelledBy {
  spa = "Spa",
  customer = "Customer"
};

type BookingDetails = {
  id: number;
  start_time: string;
  end_time: string;
  booking_date: string;
  total_price: number;
  booking_by: string;
  cancelled_by: CancelledBy.spa | CancelledBy.customer | null;
  status: "booked" | "completed" | "cancelled";
  personal_data: {
    name: string;
    service_name?: string;
    image: string | null;
    rating: number | null;
    is_rated: boolean;
    address?: string;
    customer_rating: {
      id: number;
      catalogue_id: number | null;
      comment: string;
      rating: number;
      created_at: string;
      updated_at: string;
      spa_id: number;
      account_id: number;
    } | null;
    slot: {
      id: number;
      sub_category_id: number | null;
      duration: string;
      price: string;
      created_at: string;
      updated_at: string;
      start_time: string;
      end_time: string;
      catalogue_id: number;
    } | null;
    email: string;
    phone_number: number | null;
    country_code: number | null;
    category?: string;
    arabic_category?: string;
    sub_category_name: string | null;
    region: any | null;
    spa_image: string | null;
    service_image: string[] | null
  };
};

interface ScheduledBooking {
  id: string;
  name: string;
  color: string;
  backgroundColor: string;
  title: string;
  start: string;
  time: string;
  photo: string | null;
  price: string;
  duration: string;
  display: string;
  classNames: string[];
  category: string;
  arCategory: string;
}

export type SubCategory = {
  subcategory: {
    id: number,
    name: string
  },
  slots: {
    id: 4,
    duration: string,
    price: string,
    start_time: string,
    end_time: string
  }[]
}

type TimeSlot = {
  start_time: string;
  end_time: string;
  date: string[];
  status: string;
  same_timing: string;
};

type UserProfile = {
  id: string;
  attributes: {
    full_name: string;
    open_timing: string;
    spa_timings: TimeSlot[];
    about: string;
    about_us: string | null;
    phone_number: number | null;
    email: string;
    country_code: number;
    gender: string;
    profile_data: {
      attributes: {
        account_id: number,
        address: string | null,
        photo: string | null
      }
    }
    services: {
      subcategories_with_slots: SubCategory[]
    }[],
    reviews: {
      name: string;
      review: string;
      comment: string;
      created_at: string;
    }[];
    image_url: string | null;
    average_review: boolean,
    average_review_string: boolean,
    carousal_images: {
      id: number;
      url: string;
    }[] | null;
  }
};

export type Catalogue = {
  id: number | string,
  attributes: {
    name: string,
    region: CategorySubCategory | null,
    availabilities: {
      availability_id: number,
      capacity: number,
      dates: string[],
      duration: Duration;
      timeslots: string[]
    }[],
    images: {
      url: string
    }[] | null,
    description: string,
    sub_category: CategorySubCategory,
    category: {
      attributes: CategorySubCategory
    }
    catalogue_slots: {
      id: number,
      duration: string,
      price: string
    }[]
  }
};


interface BookingSlot {
  start_time: string;
  end_time: string;
  booking_date: string;
  service_provider_id: number;
  catalogue_id: number;
  bx_block_categories_slot_id: number;
  status: string;
  customer_id: number;
}

interface Availabilities {
  timeslots: string[];
  dates: string[];
  duration: Duration;
}

interface TimeslotData {
  id: string;
  name: string;
  availabilities: Availabilities[];
  booked_slots: BookingSlot[];
}

// Customizable Area End

export const configJSON = require('./config');

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  serviceName?: string;
  userName?: string;
  bookedID?: number;
  onCancelBooking?: (bookingId: number) => Promise<void>;
  openRescheduleModal?: boolean;
  setOpenRescheduleModal?: (arg: boolean, id?: number, name?: string) => void;
  openCancelModal?: boolean;
  setOpenCancelModal?:
  | ((open?: boolean, bookingId?: number, service_name?: string) => void)
  | ((bookingId?: number, service_name?: string) => void);
  openCustomerCancelModal?: boolean;
  setOpenCustomerCancelModal?: (arg: boolean) => void;
  modalContent?: {
    title: string;
    buttonTitle: string;
  };
  modalData?: RescheduleFormValues;
  setOpenSnack?: () => void;
  showConfirmationModal?: boolean;
  setOpenBookingConfirmationModal?: (arg: boolean) => void;
  openLoginModal?: boolean;
  setOpenLoginModal?: (arg: boolean) => void;
  durationSlots?: Duration[];
  price?: string
  openCustomerRescheduleModal?: boolean;
  setCustomerRescheduleModal?: (arg: boolean, id?: number, name?: string) => void;
  customerCatalogue?: TimeslotData | null;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  bookingFormValues: BookingFormValues;
  bookingsCount: BookingsCount;
  selectedSlot: string;
  currentPage: number;
  lastPage: number;
  customerProfiles: CustomerProfile[];
  customerProfilesPage: CustomerProfile[];
  openCancelModal: boolean;
  openCustomerCancelModal: boolean;
  openLoginModal: boolean;
  openRescheduleModal: boolean;
  openCustomerRescheduleModal: boolean;
  anchorEl: HTMLElement | null;
  currentSlotIndex: number;
  datesRange: any[];
  scheduleServiceType: string;
  showSnack: boolean;
  viewMode: string;
  isCustomer: boolean;
  isSpa: boolean;
  bookingDetails: BookingDetails | null;
  discountedPrice: number | null;
  discount_type: string;
  couponID: number;
  selectedBookingId?: number;
  selectedService: Catalogue | null;
  slotDays: string[];
  timeSlots: string[];
  scheduleDate: string;
  scheduledBookings: ScheduledBooking[];
  filteredScheduledBookings: ScheduledBooking[];
  selectedSchedule: ScheduledBooking;

  loading: boolean;
  servicesLoading: boolean;

  categories: Category[];
  services: Catalogue[];
  catalogues: Catalogue[];
  servicesOptions: CategorySubCategory[];

  buttonMode: string;
  modalName: string;
  bookingId: number;
  preview: string;
  storedBookingInfo: {
    full_name: string;
    phone_number: number | null;
    email: string;
    image_url: string;
    currency: string;
    complete_attributes: CompleteAttributes;
  } | null;
  selectedTimeSlotPrice: {
    date: string;
    time: string;
    start_time: string;
    end_time: string;
    duration: string;
    price: string;
    subcategoryName: {
      id: number | undefined;
      name: string | undefined;
    };
    slotId: number;
    accountId: number | undefined;
    catelogueId: string | undefined;
  } | null;
  isPreviewMode: boolean;
  showConfirmationModal: boolean;
  customerDetails: UserProfile | null;
  selectedCatalogue: TimeslotData | null;
  spaOpenTiming: string;
  selectedTimeSlot: {
    date: string;
    slot: {
      time: string;
      duration: string;
      ordered: boolean;
    };
  } | null;
  bookingDate: string,
  showRestrictedPrompt: boolean,
  modalConfig: ModalConfig,
  currentSubscription: null | number,
  userAction?: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Ordermanagement1Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  currency = '';
  mounted: boolean = false;
  imagesCount = 9;
  apiGetBookingsCallId = "";
  apiGetUserDetails = '';
  apiGetBookingDetailsCallId = '';
  apiGetCategoriesCallId = "";
  apiPutCancelBookingCallId = "";
  apiGetServicesCallId = "";
  apiPatchRescheduleBookingCallId = "";
  apiPostCreateBookingCallId = "";
  apiGetScheduleCallId = "";
  apiPostCustomerDetailsCallId = "";
  apiGetCustomerDetailsCallId = "";
  apiCompleteBookingCallId = "";
  apiGetCataloguesCallId = "";
  apiGetCataloguesDetailsCallId = "";
  apiGetUserCurrentSubscriptionId = "";
  applyCouponsDetailsById = "";
  pathParam = this.props.navigation.getParam("navigationBarTitleText", 0);
  private formattedSlotsCache: { [key: string]: any[] } = {};

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.handleBookNow = this.handleBookNow.bind(this);

    // Customizable Area Start
    (async () => {
      this.currency = await getStorageData('currency');
    })();

    this.cancelBooking = this.cancelBooking.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: '',
      txtSavedValue: 'A',
      enableField: false,
      // Customizable Area Start
      bookingFormValues: initBookingFormValues,
      bookingsCount: {
        completed: 0,
        booked: 0,
        cancelled: 0,
      },
      selectedSlot: "",
      currentPage: 1,
      lastPage: 0,
      customerProfiles: [],
      customerProfilesPage: [],
      openCancelModal: false,
      openCustomerCancelModal: false,
      openLoginModal: false,
      openRescheduleModal: false,
      openCustomerRescheduleModal: false,
      anchorEl: null,
      currentSlotIndex: 0,
      datesRange: [],
      scheduleServiceType: '',
      showSnack: false,
      viewMode: 'completed',
      isCustomer: false,
      isSpa: false,
      bookingDetails: null,
      discountedPrice: null,
      discount_type: "",
      couponID: 0,
      selectedBookingId: undefined,
      selectedService: null,
      slotDays: [],
      timeSlots: [],
      scheduleDate: "",
      scheduledBookings: [],
      filteredScheduledBookings: [],
      selectedSchedule: initScheduleItem,

      loading: false,
      servicesLoading: false,

      categories: [],
      services: [],
      servicesOptions: [],
      catalogues: [],

      buttonMode: "",
      modalName: "",
      bookingId: 0,
      preview: "",
      storedBookingInfo: null,
      selectedTimeSlotPrice: null,
      isPreviewMode: false,
      showConfirmationModal: false,
      customerDetails: null,
      selectedCatalogue: null,
      spaOpenTiming: "",
      selectedTimeSlot: null,
      bookingDate: '',
      showRestrictedPrompt: false,
      modalConfig: {
        title: '',
        message: '',
        confirmText: '',
        cancelText: '',
        onConfirm: () => { },
      },
      currentSubscription: null
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    this.mounted = true;
    await this.getCustomerDetails();
    try {
      const userRole = await getStorageData('userRole');

      this.setState({
        isCustomer: userRole === 'Customer',
        isSpa: userRole === 'Spa',
      });

      const discountPriceStr = await getStorageData('appliedCoupon');
      if (discountPriceStr) {
        const appliedCoupon = JSON.parse(discountPriceStr);
        const discountPrice = parseFloat(appliedCoupon.discount);
        const discountType = appliedCoupon.discount_type
        const coupon_Id = parseInt(appliedCoupon.couponId);
        this.setState({ discountedPrice: discountPrice, discount_type: discountType, couponID: coupon_Id });
      }
      const bookingInfoStr = await getStorageData('bookingInfo');
      if (bookingInfoStr) {
        const bookingInfo = JSON.parse(bookingInfoStr);
        this.setState({ storedBookingInfo: bookingInfo });
      }
      const timeSlotInfo = await getStorageData('selectedTimeSlotPrice');
      if (timeSlotInfo) {
        const slotInfo = JSON.parse(timeSlotInfo);
        this.setState({ selectedTimeSlotPrice: slotInfo });
      }
    } catch (error) {
    }

    const urlParams = new URLSearchParams(window.location.search);
    const pathParts = window.location.pathname.split('/');
    const id = Number(pathParts[pathParts.length - 1]);
    const urlData = window.location.pathname.split('/');
    const preview = urlData[urlData.length - 1];
    const previewMode = preview === 'preview';
    const buttonMode = pathParts[pathParts.length - 1].split('=')[1];

    if (!isNaN(id)) {
      this.pathParam = id;
    }
    const confirmed = urlParams.get('confirmed');
    if (confirmed === 'true') {
      this.setState({ showConfirmationModal: true });
    }

    if (preview === 'preview') {
      this.setState({ preview, isPreviewMode: previewMode });
    }

    if (buttonMode) {
      this.setState({ buttonMode, bookingId: id });
    } else {
      this.setState({ bookingId: id });
    }

    if (this.pathParam) {

      await this.getBookingDetails(this.pathParam);
    } else if (window.location.pathname.toLowerCase() === "/spabookingform") {
      this.getCategories();
    } else {
      await this.getBookings();
    }
  }

  async componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<S>
  ): Promise<void> {
    if (this.state.viewMode !== prevState.viewMode) {
      await this.getBookings(this.state.viewMode);
    }
    if (this.state.scheduleDate !== prevState.scheduleDate) {
      await this.getSchedule();
    }
  }

  async componentWillUnmount(): Promise<void> {
    this.mounted = false;
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog('Message Recived', message);

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        'Change Value',
        'From: ' + this.state.txtSavedValue + ' To: ' + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const navigationData = message.getData(getName(MessageEnum.NavigationPayLoadMessage));

    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    const { bookings, buttonMode } = this.getDevidedResultValue(navigationData, {});
    if (this.getCombinedLogicalValue(
      this.getCombinedLogicalValue(!this.state.bookingDetails, navigationData),
      this.getCombinedLogicalValue(bookings, buttonMode))
    ) {
      this.setState({
        bookingDetails: Array.isArray(bookings) ? bookings[0] : bookings,
        buttonMode: buttonMode,
        loading: false,
      });
    }

    this.handleAPIData(message);

    if (responseJson) {
      if (apiRequestCallId === this.apiPostCustomerDetailsCallId) {
        const bookingId = responseJson.data?.id;
        this.redirectToBooking(bookingId);
      }
      if (apiRequestCallId === this.apiGetCustomerDetailsCallId) {
        this.setState({ customerDetails: responseJson.data })
      }
      if (apiRequestCallId === this.apiGetUserCurrentSubscriptionId) {
        const activeSubscr = responseJson.data.find((subscription: any) => subscription.status.toLowerCase() === 'active');
        this.setState({ currentSubscription: activeSubscr?.plan_id })
      }
    }

    if (errorReponse) {
      this.setState({ loading: false, servicesLoading: false });
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: 'email',
    keyboardType: 'email-address',
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  setSelectedService = (id: string) => {
    const selectedService =
      this.state.services.find((service: Catalogue) => service.id === id) ||
      null;
    this.setState({ selectedService });

    return selectedService;
  };

  // Customizable Area Start
  redirectToBooking = async (bookingId: string) => {
    if (bookingId) {
      window.location.href = `/BookingDetails/${bookingId}?confirmed=true`;

      await this.applyCoupon(bookingId);
    }
  }

  setBookingDetails = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.apiGetBookingDetailsCallId) {
      if (!responseJson || !responseJson.data || !responseJson.data.detail) {
        if (this.mounted) {
          this.setState({ loading: false });
        }
        return;
      }

      const bookingDetails = responseJson.data.detail;
      if (this.mounted) {
        this.setState({ bookingDetails, loading: false });
      }
    }
  };

  setBookings = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.apiGetBookingsCallId) {
      if (!responseJson || !responseJson.data) {
        this.setState({ loading: false });
        return;
      }
      const bookingsList = responseJson.data[this.state.viewMode];
      const resultBookings = this.state.viewMode === 'booked' ? bookingsList.reverse() : bookingsList;
      const bookings = this.getDevidedResultValue(
        resultBookings,
        []
      ).map((booking: BookingDetails) => ({
        id: booking.id,
        image_url: booking.personal_data.image || defaultProfile,
        name: booking.personal_data.name,
        shortDescription: booking.personal_data.service_name,
        date: moment(booking.booking_date).format('YYYY-MM-DD'),
        time: {
          start: booking.start_time,
          end: booking.end_time
        }
      }));
      this.setState({
        bookingsCount: responseJson.data.count,
        lastPage: Math.ceil(bookings.length / this.imagesCount),
        customerProfiles: bookings,
        customerProfilesPage: bookings.slice(0, this.imagesCount),
        loading: false,
      });
    }
  };

  setBookingFormData = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.apiGetCategoriesCallId) {
      this.setState({ categories: responseJson.data });
    }
    if (apiRequestCallId === this.apiGetServicesCallId) {
      const servicesOptions = responseJson.data.map((service: Catalogue) => ({
        id: service.id,
        name: service.attributes.name,
      }));
      this.setState({
        servicesOptions,
        services: responseJson.data,
        servicesLoading: false,
      });
    }
  };

  applyCancelBooking = async (apiRequestCallId: string) => {
    if (apiRequestCallId === this.apiPutCancelBookingCallId) {
      this.setOpenCancelModal();
      if (this.state.isCustomer) {
        this.goToCustomerDetails();
      } else {
        this.gotoMyBooking();
      }
      await this.getBookings(this.state.viewMode);
    }
  };

  confirmBookingData = (apiRequestCallId: string) => {
    if (
      apiRequestCallId === this.apiPatchRescheduleBookingCallId ||
      apiRequestCallId === this.apiPostCreateBookingCallId
    ) {
      if (this.state.isCustomer) {
        this.goToCustomerDetails();
      } else {
        this.gotoMyBooking();
      }
    }
  };

  removeTimezone = (time: string) => time.split('+')[0];

  setBookingSchedule = (apiRequestCallId: string, responseJson: any) => {
    const serviceNumbers: Record<string, number> = {};
    // Set initial index value to start with zero
    let individualIndex = -1;

    if (apiRequestCallId === this.apiGetScheduleCallId) {
      const data = responseJson.data.booked_slots;
      const scheduledBookings = data.map((booking: BookingDetails, index: number) => {
        const serviceName = String(booking.personal_data.service_name);

        if (isNaN(serviceNumbers[serviceName])) {
          serviceNumbers[serviceName] = ++individualIndex;
        }

        const color = bookingColorTable[serviceNumbers[serviceName]];

        const startBookingTime = this.removeTimezone(booking.start_time);
        const endBookingTime = this.removeTimezone(booking.end_time);

        return ({
          id: `${booking.id}`,
          name: booking.personal_data.name,
          color,
          title: serviceName,
          start: moment(booking.start_time.split(' ')[0]).format('YYYY-MM-DD'),
          time: `${moment(startBookingTime).format('D MMM, hh:mma')}-${moment(endBookingTime).format('hh:mma')}`,
          photo: booking.personal_data.image,
          price: booking.personal_data.slot?.price,
          duration: booking.personal_data.slot?.duration,
          display: 'list-item',
          category: booking.personal_data.category,
          arCategory: booking.personal_data.arabic_category,
          // backgroundColor is not applied for event with display: 'list-item', so this classNames approach persists here
          classNames: [`card-${serviceNumbers[serviceName]}`],
        })
      })
        .sort((bookingFirst: ScheduledBooking, bookingNext: ScheduledBooking) => bookingFirst.start > bookingNext.start ? -1 : 1);
      this.setState({
        loading: false,
        scheduledBookings,
        filteredScheduledBookings: scheduledBookings
      });
    }
  };

  handleAPIData = async (message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (responseJson) {
      this.setBookingDetails(apiRequestCallId, responseJson);
      this.setBookings(apiRequestCallId, responseJson);
      this.setBookingFormData(apiRequestCallId, responseJson);
      this.setBookingSchedule(apiRequestCallId, responseJson);
      this.setCatalogues(apiRequestCallId, responseJson);
      this.applyCancelBooking(apiRequestCallId);
      this.confirmBookingData(apiRequestCallId);
      this.setCatalogues(apiRequestCallId, responseJson);
      this.setCatalogueDetails(apiRequestCallId, responseJson)
      this.setCompleteBooking(apiRequestCallId);
    }
  };

  setCompleteBooking = (apiRequestCallId: string) => apiRequestCallId === this.apiCompleteBookingCallId && this.gotoMyBooking();


  setCatalogues = (apiRequestCallId: string, responseJson: any) => {
    apiRequestCallId === this.apiGetCataloguesCallId && this.setState({ catalogues: responseJson.data });
  }

  gotoMyBooking = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationBooking)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  gotoBookingForm = () => {
    if (this.handleUserAction()) {
      const message: Message = new Message(
        getName(MessageEnum.NavigationBookingForm)
      );
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
    }
  };

  gotoCalendar = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationBookingCalendar)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  gotoBookingDetails = async (id: number) => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationBookingDetails)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), id);
    this.send(message);
  };

  // Coupons Navigation adding
  goToCouponsPage = (id: number | string) => {
    const url = `/Coupons?id=${id}`;
    const message: Message = new Message(
      getName(MessageEnum.NavigationCouponPage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
    window.location.href = url;
  };

  getAutocompleteStyle = (isError: boolean) => ({
    padding: '12px 16px',
    paddingRight: '32px',
    borderRadius: '8px',
    ...(isError && { border: '1px solid red' }),
  });

  setSelectedSlot = (slot: string) => {
    this.setState({ selectedSlot: slot });
  };

  setTimeSlots = (timeSlots: string[]) => {
    this.setState({ timeSlots })
  }

  setSlotDays = (slotDates: string[]) => {
    const slotDays = slotDates.map((dateItem) => dayjs(dateItem).format("ddd"));
    this.setState({ slotDays });
  };

  goForward = () => {
    const profiles = this.state.customerProfiles;
    const startIndex = this.state.currentPage * this.imagesCount;
    const endIndex = startIndex + this.imagesCount;
    const customerProfilesPage = profiles.slice(startIndex, endIndex);
    if (customerProfilesPage.length) {
      this.setState({
        currentPage: this.state.currentPage + 1,
        customerProfilesPage,
      });
    }
  };

  goBack = () => {
    const profiles = this.state.customerProfiles;
    const endIndex = (this.state.currentPage - 1) * this.imagesCount;
    const startIndex = endIndex - this.imagesCount;
    const customerProfilesPage = profiles.slice(startIndex, endIndex);
    if (customerProfilesPage.length) {
      this.setState({
        currentPage: this.state.currentPage - 1,
        customerProfilesPage,
      });
    }
  };

  getGoBackColor = () => (this.state.currentPage > 1 ? '#398378' : '#94A3B8');

  getGoForwardColor = () =>
    this.state.currentPage * this.imagesCount <
      this.state.customerProfiles.length
      ? '#398378'
      : '#94A3B8';

  pushOrRemoveIndex = (targetArray: any[], item: string | number) => {
    const slotIdentIndex = targetArray.indexOf(item as never);
    slotIdentIndex
      ? targetArray.splice(slotIdentIndex, 1)
      : targetArray.push(item as never);
    return targetArray;
  };

  getLogicalValue(firstValue: boolean, secondValue: React.ReactNode): React.ReactNode | null {
    return firstValue ? secondValue : null;
  }

  getTernaryValue(condition: boolean, firstValue: any, secondValue: any) {
    return condition ? firstValue : secondValue;
  }

  getCombinedLogicalValue(condition1: boolean, condition2: boolean, condition3?: boolean | Element): boolean {
    return condition3 ? (condition1 && condition2 && Boolean(condition3)) : (condition1 && condition2);
  }

  getDevidedResultValue<T>(condition1: T, condition2: T): T {
    return condition1 || condition2;
  }

  getErrorText(touched?: any, errorText?: string) {
    return touched && errorText;
  }

  getResultValue<T>(sourceValue1: T, sourceValue2: T, sourceValue3: T): T {
    return sourceValue1 && sourceValue2 && sourceValue3
  }

  getEmptyObject<T>(objectValue: T): T | {} {
    return objectValue || {}
  }

  setOpenCancelModal = (id?: number) =>
    this.handleUserAction() &&
    this.setState((prev) => ({
      openCancelModal: !prev.openCancelModal,
      selectedBookingId: id || undefined,
    }));

  selectedBookingUserName = () => {
    if (this.state.selectedBookingId) {
      const selectedProfile = this.state.customerProfiles.find(
        (profile) => profile.id === this.state.selectedBookingId
      );
      return selectedProfile?.customerInfo?.fullName;
    }
  };

  setOpenCustomerCancelModal = (value: boolean) =>
    this.setState({ openCustomerCancelModal: value });

  setOpenLoginModal = (value: boolean) =>
    this.setState({ openLoginModal: value });

  setOpenBookingConfirmationModal = (value: boolean) =>
    this.setState({ showConfirmationModal: value });

  setOpenRescheduleModal = (open: boolean, id?: number, name?: string) =>
    this.handleUserAction() && this.setState({
      openRescheduleModal: open,
      modalName: name || 'Default Name',
      selectedBookingId: id || undefined,
    });

  setScheduleCancelModal = (id: string) => {
    if (this.handleUserAction()) {
      const selectedSchedule =
        this.state.scheduledBookings.find((booking) => booking.id === id) ||
        initScheduleItem;
      this.setState((prev) => ({
        openRescheduleModal: !prev.openRescheduleModal,
        selectedSchedule,
      }));
    }
  };

  setOpenSnack = () => this.setState({ showSnack: true });
  setDatesRange = (value: any) => this.setState({ datesRange: value });
  setScheduleServiceType = (event: React.ChangeEvent<{ name?: string; value: any }>, t: Record<string, string>) => {
    const selectedValue = event.target.value;
    const filteredScheduledBookings = this.state.scheduledBookings.filter(
      (booking: ScheduledBooking) =>
        selectedValue === t['booking-schedule-all-services'] ?
          booking.category :
          booking.category === selectedValue);

    this.setState({
      scheduleServiceType: selectedValue,
      filteredScheduledBookings
    });
  }
  getHighlightSlotStyle = (slot: string) =>
    this.state.selectedSlot === slot
      ? {
        backgroundColor: '#398378ff',
        color: '#ffffff',
      }
      : {};
  getDateError = (isDateError: boolean) =>
    isDateError ? { border: '1px solid #DC2626' } : {};
  getInputError = (isTouched?: boolean, error?: string) =>
    !!(isTouched && error);
  renderInitSelect = (value: string, placeholder: string) =>
    value
      ? undefined
      : () => (
        <div style={{ color: '#94A3B8' } as React.CSSProperties}>
          {placeholder}
        </div>
      );
  getButtonBorder = (buttonName: string) => {
    const viewMode = this.state.viewMode;
    const name = viewMode === buttonName ? viewMode : 'defaultStyles';

    const styleVariations: any = {
      completed: { border: '1px solid #059669', backgroundColor: '#D1FAE5' },
      booked: { border: '1px solid #D97706', backgroundColor: '#FEF3C7' },
      cancelled: { border: '1px solid #DC2626', backgroundColor: '#FEE2E2' },
      defaultStyles: { border: '#CBD5E1', backgroundColor: '#FFFFFF' },
    };

    return styleVariations[name];
  };

  handleNavigate = (route: string) => {
    this.props.navigation.navigate(route);
  };

  async getBookings(status = "completed") {
    this.setState({ loading: true });
    const authToken = await getStorageData("authToken");
    const headers = {
      "Content-Type": "application/json",
      token: authToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetBookingsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.bookingsAPIEndPoint}?status=${status}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.bookingDetailsApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async getBookingDetails(id: number) {
    if (isNaN(id)) {
      return;
    }
    this.setState({ loading: true });
    const authToken = await getStorageData('authToken');
    const headers = {
      'Content-Type': 'application/json',
      token: authToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetBookingDetailsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.userBookingAPIEndPoint}?id=${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.bookingDetailsApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCategories() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCategoriesCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.bookingDetailsApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async getUserCurrentSubscription() {
    const headers = {
      'token': await getStorageData('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.currentSubscriptionApiUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGet
    );

    this.apiGetUserCurrentSubscriptionId = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async getServices(category: string, subCategory: string) {
    this.setState({ servicesLoading: true, services: [] });
    const authToken = await getStorageData("authToken");
    const headers = {
      "Content-Type": "application/json",
      token: authToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetServicesCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.serviceApiEndPoint}?id=${category}&sub_category_id=${subCategory}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.bookingDetailsApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async getCatalogues() {
    this.setState({ loading: true });
    const authToken = await getStorageData('authToken');

    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCataloguesCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userCatalogueAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async cancelBooking(id: number) {
    const authToken = await getStorageData("authToken");
    const headers = {
      "Content-Type": "application/json",
      token: authToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPutCancelBookingCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.cancelBookingApiEndPoint}?id=${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.cancelBookingApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async rescheduleBooking(data: any, id: string) {
    const authToken = await getStorageData("authToken");
    const headers = {
      "Content-Type": "application/json",
      token: authToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPatchRescheduleBookingCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.rescheduleBookingApiEndPoint}/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ data })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.rescheduleBookingApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async createBooking(data: any) {
    const authToken = await getStorageData("authToken");
    const headers = {
      "Content-Type": "application/json",
      token: authToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPostCreateBookingCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createBookingApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ data })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async completeBooking(data: any) {
    const authToken = await getStorageData("authToken");
    const headers = {
      "Content-Type": "application/json",
      token: authToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCompleteBookingCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.completeBookingApiEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.completeBookingApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async getSchedule() {
    this.setState({ loading: true });
    const authToken = await getStorageData("authToken");
    const headers = {
      "Content-Type": "application/json",
      token: authToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetScheduleCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.scheduleApiEndPoint}?date=${this.state.scheduleDate}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.bookingDetailsApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getSimpleCategories = (): CategorySubCategory[] => {
    const allCategories = this.state.catalogues.map((catalogue) => {
      const { id, name } = catalogue.attributes.category.attributes || {}
      return JSON.stringify({ id: String(id), name })
    })
    return Array.from(new Set(allCategories)).map((category) => JSON.parse(category))
  }

  getSubCategories = (categoryId: string): CategorySubCategory[] => {
    return this.state.categories
      .find((category) => category.id === categoryId)
      ?.attributes.sub_categories.map((subCategory) => ({
        id: subCategory.id,
        name: subCategory.name,
      })) || [];
  }

  generateTimeSlots = (slotDuration: string, timeSlots?: string[]) => {
    if (timeSlots) {
      this.setState({ timeSlots });
      return timeSlots;
    }
    const timeStrings = calculateSlots("10:00 am - 06:00 pm", slotDuration).map(
      (timeObject) => timeObject.time
    );
    this.setState({ timeSlots: timeStrings });
    return timeStrings;
  };

  removeTimeZoneOffset(dateTimeString: string): string {
    if (!dateTimeString) {
      return "N/A";
    }

    let cleanedString = dateTimeString;

    if (cleanedString.endsWith('+00:00')) {
      cleanedString = cleanedString.slice(0, -6);
    }

    const offsetIndex = cleanedString.lastIndexOf('+');
    if (offsetIndex !== -1) {
      return cleanedString.substring(0, offsetIndex).trim();
    }

    if (cleanedString.endsWith('Z')) {
      return cleanedString.slice(0, -1);
    }

    return cleanedString;
  }

  formatTime(dateTimeString: string, locale?: string, noOffset?: boolean): string {
    const cleanedString = noOffset ? dateTimeString : dateTimeString.replace(' +00:00', '');
    const date = new Date(cleanedString);

    if (isNaN(date.getTime())) {
      return cleanedString;
    }

    const formattedTime = date.toLocaleString(locale || 'en-US', {
      hour: '2-digit',
      minute: 'numeric',
      hour12: true,
    });

    return formattedTime;
  }

  formatDate(dateTimeString: string, locale?: string) {
    const date = new Date(dateTimeString);
    if (isNaN(date.getTime())) {
      return '';
    }
    const formattedDate = date
      .toLocaleString(locale || 'en-US', {
        month: 'long',
        day: '2-digit',
      })
      .toUpperCase()
      .replace(',', '');

    return ` ${formattedDate}`;
  }

  isValidDate(dateTimeString: string) {
    const date = new Date(dateTimeString);
    return !isNaN(date.getTime());
  }

  returnAvailTime = (time: string | undefined, locale?: string): string => (time && this.formatTime(time, locale)) ?? '';
  returnAvailTimeNotFormatted = (time: string | undefined, locale?: string) => time ? this.formatTime(time, locale, true) : '';

  calculateBookingPriceWithDiscount(bookingPrice: number, appliedCoupon?: any): number {
    if (appliedCoupon && appliedCoupon.discount_type) {
      const discount = appliedCoupon.discount ?? 0;
      switch (appliedCoupon.discount_type) {
        case 'percentage':
          return bookingPrice / (1 - discount / 100);
        case 'fixed amount':
          return bookingPrice - parseFloat(discount);
        default:
          return bookingPrice;
      }
    } else {
      return bookingPrice;
    }
  }
  calculateFinalPrice(bookingPrice: number, discountedPrice: number | null, discountType?: string): number | undefined {
    if (discountedPrice !== null) {
      if (discountType === 'percentage') {
        return parseFloat((bookingPrice * (1 - discountedPrice / 100)).toFixed(2));
      } else if (discountType === 'fixed amount') {
        return parseFloat((bookingPrice - discountedPrice).toFixed(2));
      }
    } else {
      return bookingPrice;
    }
  }

  calculateFinalPriceBooking(bookingPrice: number, discountedPrice: number | null): number {
    if (discountedPrice !== null) {
      return bookingPrice * (discountedPrice / 100);
    } else {
      return bookingPrice;
    }
  }
  formatTimesBasedOnMode(
    previewStartTime: string | undefined,
    previewEndTime: string | undefined,
    bookingStartTime: string | undefined,
    bookingEndTime: string | undefined,
    isPreviewMode: boolean,
    locale?: string
  ): { formattedStartTime: string; formattedEndTime: string } {
    let startTime, endTime;

    if (isPreviewMode) {
      startTime = this.returnAvailTimeNotFormatted(previewStartTime, locale);
      endTime = this.returnAvailTimeNotFormatted(previewEndTime, locale);
    } else {
      startTime = this.returnAvailTime(bookingStartTime, locale);
      endTime = this.returnAvailTime(bookingEndTime, locale);
    }

    return { formattedStartTime: startTime, formattedEndTime: endTime };
  }

  handleBookNow = async () => {
    const dateTime = this.state.selectedTimeSlotPrice;

    const storedBookingInfo = this.state.storedBookingInfo;
    const customerDetails = this.state.customerDetails

    if (!dateTime || !storedBookingInfo || !customerDetails) {
      if (!dateTime) {
        console.error('Required date and time information is missing');
      }
      if (!storedBookingInfo) {
        console.error('Stored booking info is missing');
      }
      if (!customerDetails) {
        console.error('Customer details are missing');
      }
      return;
    }

    const discountedTotal = this.getTernaryValue(
      this.state.discountedPrice !== null,
      this.calculateFinalPriceBooking(parseFloat(dateTime.price), this.state.discountedPrice).toString(),
      0
    );

    const finalPrice = parseFloat(dateTime.price) - parseFloat(discountedTotal)

    const [startTimeString, endTimeString] = dateTime.time.split(' - ');

    const startTimeLocal = moment(startTimeString, 'hh:mm a');
    const endTimeLocal = moment(endTimeString, 'hh:mm a');

    const bookingDate = moment(dateTime.date).format('YYYY-MM-DD');

    const startTime = moment(`${bookingDate} ${startTimeLocal.format('HH:mm:ss')}`).format('YYYY-MM-DD HH:mm:ss');
    const endTime = moment(`${bookingDate} ${endTimeLocal.format('HH:mm:ss')}`).format('YYYY-MM-DD HH:mm:ss');

    const bookingData = {
      data: {
        start_time: startTime,
        end_time: endTime,
        service_provider_id: dateTime.accountId,
        booking_date: dateTime.date,
        timezone: 'UTC',
        catalogue_id: dateTime.catelogueId,
        bx_block_categories_slot_id: dateTime.slotId,
        full_name: customerDetails.attributes.full_name,
        phone_number: customerDetails.attributes.phone_number,
        country_code: customerDetails.attributes.country_code,
        email: customerDetails.attributes.email,
        gender: customerDetails.attributes.gender,
        price: finalPrice,
      },
    };

    await this.postCustomerBookingDetails(bookingData);
    await removeStorageData("appliedCoupon");
    await removeStorageData("selectedTimeSlotPrice")
    return bookingData;
  };


  async applyCoupon(bookingId: string) {

    const couponID = this.state.couponID

    const authToken = await getStorageData('authToken');
    const userId = await getStorageData('user_id');
    const headers = {
      "Content-Type": "application/json",
      "token": authToken
    };

    const bodyData = {
      data: {
        coupon_code_id: couponID,
        bx_block_appointment_management_booked_slot_id: bookingId,
        user_id: userId
      }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.applyCouponsDetailsById = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.appliedCouponAPIEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.appliedCouponApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async postCustomerBookingDetails(bookingData: any) {
    const authToken = await getStorageData('authToken');
    const headers = {
      'Content-Type': configJSON.exampleApiContentType,
      token: authToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPostCustomerDetailsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createBookingsApiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bookingData)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCombinedAddress(attrs?: CompleteAttributes): string {
    return [attrs?.address, attrs?.city, attrs?.postal_code, attrs?.country]
      .filter(part => part && part.trim() !== '')
      .join(', ');
  }

  goToLoginFromBooking = () => {
    const msg: Message = new Message(getName(MessageEnum.NavigationEmailLogInMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationPayLoadMessage), { isPreviewMode: true });
    this.send(msg);
  }

  handleButtonClick = async () => {
    const authToken = await getStorageData('authToken');
    if (!authToken) {
      this.setOpenLoginModal(true);
      return;
    }
    this.handleBookNow();
  }

  goToCustomerDetails = () => {
    const msg: Message = new Message(getName(MessageEnum.NavigationMyBookings));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  async getCustomerDetails() {
    const userID = await getStorageData('user_id');
    const headers = {
      "Content-Type": configJSON.apiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCustomerDetailsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.userDetailsAPIEndPoint}?id=${userID}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.userDetailsApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getOpenTiming = (openTiming: string | undefined, futureOpenTiming: string) =>
    (openTiming && openTiming !== 'Close') ? String(openTiming) : futureOpenTiming;

  isBooleanAnd = (first: boolean, second: boolean) => first && second;

  isNotFamStatus = () => !(this.state.buttonMode === 'completed' || this.state.buttonMode === 'cancelled' || this.state.buttonMode === 'booked')

  setCatalogueDetails = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.apiGetCataloguesDetailsCallId) {
      const { id, attributes } = responseJson.data;
      const { name, availabilities, booked_slots } = attributes;
      if (availabilities && availabilities.length > 0) {
        const processedAvailabilities = availabilities.map((availability: { timeslots: any; dates: any; duration: any }) => ({
          timeslots: availability.timeslots,
          dates: availability.dates,
          duration: availability.duration
        }));

        this.setState({
          selectedCatalogue: {
            id,
            name,
            booked_slots,
            availabilities: processedAvailabilities
          }
        }, () => {
          this.forceUpdate();
        });
      }
    }
  };

  getCataloguesDetails = async (catalogue_id: number | undefined) => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCataloguesDetailsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.cataloguesApiEndPoint}/${catalogue_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  formatSpaTiming(spa_timing: string) {
    if (!spa_timing) return '';

    try {
      const spaTiming = JSON.parse(spa_timing);
      const formattedTiming = spaTiming.map((timing: { start_time: moment.MomentInput; end_time: moment.MomentInput; }) => {
        const startTime = moment(timing.start_time, 'h:mm a');
        const endTime = moment(timing.end_time, 'h:mm a');
        return `${startTime.format('h:mm a')} - ${endTime.format('h:mm a')}`;
      });
      return formattedTiming.join(', ');
    } catch (error) {
    }
  }


  formatISODateString(dateString: string): string {
    return dateString;
  }

  formatBookedSlots(bookedSlots: any[]) {
    return bookedSlots.map((slot: any) => {
      const parseTime = (timeStr: string) => {
        if (timeStr.endsWith(' +00:00')) {
          return moment(timeStr.slice(0, -6));
        } else {
          return moment(timeStr);
        }
      };

      const startTime = parseTime(slot.start_time);
      const endTime = parseTime(slot.end_time);

      if (!startTime.isValid() || !endTime.isValid()) {
        return {
          booking_date: slot.booking_date,
          time_range: 'Invalid Date - Invalid Date'
        };
      }

      const formatTime = (time: moment.Moment) => {
        return time.format('h:mm A')
      };

      return {
        booking_date: slot.booking_date,
        time_range: `${formatTime(startTime)} - ${formatTime(endTime)}`
      };
    });
  }

  filterBookedSlots = (
    slots: { time: string; date: string }[],
    bookedSlots: { time: string; date: string }[]
  ) => {
    return slots.filter((slot) => {
      const isBooked = bookedSlots.some((bookedSlot) => {
        return bookedSlot.time === slot.time && bookedSlot.date === slot.date;
      });
      return !isBooked;
    });
  };


  getFormattedSlots(): any[] {
    const { selectedCatalogue } = this.state;
    const catalogueKey = selectedCatalogue?.booked_slots?.map(slot => slot.booking_date).join(',') || '';

    if (!this.formattedSlotsCache[catalogueKey]) {
      this.formattedSlotsCache[catalogueKey] = this.formatBookedSlots(selectedCatalogue?.booked_slots || []);
    }
    return this.formattedSlotsCache[catalogueKey];
  }

  gotoMyBookings = () => {
    const msg: Message = new Message(getName(MessageEnum.NavigationMyBookings));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  gotoSubscriptionPlans = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationSubscriptionPlans)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationPayLoadMessage), { isUpdate: true });

    this.send(message);
  }

  formatDateToTime = (dateString: string, isArabic?: boolean, isDate?: boolean) => {
    const locale = isArabic ? 'ar' : 'en';
    return moment(new Date(dateString.split('+')[0])).locale(locale).format(isDate ? 'YYYY-MM-DD' : 'hh:mm A');
  }

  getScheduleCategoriesList = (serviceType: string, isArabic: boolean) => {
    const bookingTypes = this.state.scheduledBookings.map((booking) => isArabic ? booking.arCategory : booking.category);
    bookingTypes.unshift(serviceType);

    return Array.from(new Set(bookingTypes));
  }

  setBookingDate = (value: string) => {
    this.setState({ bookingDate: value })
  }

  setInitScheduleServiceType = (value: string) => {
    this.setState({ scheduleServiceType: value })
  }

  getBookingDetailsByRef = (catalogue_id: number) => {
    if (catalogue_id && !(this as any).catalogueRef.current) {
      this.getCataloguesDetails(catalogue_id);

      (this as any).catalogueRef.current = true;
    }
  }

  getAvailabilities = () => this.state.catalogues
    ?.find((catalogue) => catalogue.attributes.name === this.state.bookingDetails?.personal_data.service_name)
    ?.attributes.availabilities || this.state.selectedCatalogue?.availabilities;

  setShowRestrictedPrompt = (open: boolean, config = {}) => {
    this.setState({
      showRestrictedPrompt: open,
      modalConfig: { ...this.state.modalConfig, ...config },
    });
  };


  handleUserAction = (expired?: boolean) => applyUserAction(this, expired);

  goToLandingPageVenues = () => {
    const msg: Message = new Message(getName(MessageEnum.NavigationVenueMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  // Customizable Area End
}
